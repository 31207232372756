export class Tournament501MatchSetRounds {
  static defaultOptions = {
    startingPoints: 501,
    arrowsPerRound: 3,
    matchIndexNumber: 0,
    matchSet: [0, 0],
    playerStartingFirstRound: true, /* true = home team player, false = away team player. */
    readOnlyMode: true
  }

  private classOptions: { startingPoints: number; arrowsPerRound: number; matchIndexNumber: number; matchSet: number[]; playerStartingFirstRound: boolean; readOnlyMode: boolean }
  private roundScore: number[][] = []
  private totalMatch180ScoreAmount = [0, 0]
  private roundsAreCompleted = false // If the match set has been completed, the value is set to true, otherwise its value is false.
  private winningPlayer: boolean | null = null // This is used to indicate whether a player has won the match set, a which player is the winner. Null value means no winner is found (yet).
  private numberOfClosingArrows: number | null = null
  private remainingPointsAtTheFinalRound: number | null = null
  private currentRound = 0
  private realRoundsPlayed = [0, 0]
  private currentPlayerTurn = true

  constructor (options = {}) {
    this.classOptions = Object.assign({}, Tournament501MatchSetRounds.defaultOptions, options)
    this.currentPlayerTurn = this.classOptions.playerStartingFirstRound
    this.roundScore.push([0, 0])
  }

  public setMatchSet (setValArr = [0, 0]) : void {
    this.classOptions.matchSet = setValArr
  }

  public setPlayerStartingFirstRound (player: boolean) : void {
    this.classOptions.playerStartingFirstRound = player
    this.currentPlayerTurn = player
  }

  // Set the current player turn.
  public setCurrentPlayerTurn (isHomePlayerTurn : boolean) : void {
    this.currentPlayerTurn = isHomePlayerTurn
  }

  // Switch turn to set a score entry to the next player.
  public nextPlayerTurn () : void {
    // WIP
    if (!this.classOptions.readOnlyMode) {
      //
      this.realRoundsPlayed[(this.currentPlayerTurn ? 1 : 0)]++

      if (this.currentPlayerTurn === this.classOptions.playerStartingFirstRound) {
        // Just advance the turn to the other player from another team.
        this.currentPlayerTurn = !this.currentPlayerTurn
      } else {
        // Start a new round.
        this.nextRound()
      }
    }
  }

  public updateNextPlayerTurnOnEditRemainingScore (selectedRemainingIndex: unknown) : void {
    // WIP
    if (!this.classOptions.readOnlyMode) {
      //
      const currentPlayerTurnInt = this.currentPlayerTurn ? 1 : 0
      // If both are is same, means user edited remainingScore for current player only, then update player turn as per existing logics
      if (currentPlayerTurnInt === selectedRemainingIndex) {
        this.nextPlayerTurn()
      } else {
        const currentEditingIndex = selectedRemainingIndex ? 1 : 0
        const selectedIndex = selectedRemainingIndex ? 0 : 1
        if (this.realRoundsPlayed[currentEditingIndex] === this.realRoundsPlayed[selectedIndex]) {
          // Else update both players played round (because now there is turn of player A & you are updating B, so for A, we are skipping & it will be 0 value for A) & update next round
          this.realRoundsPlayed[0]++
          this.realRoundsPlayed[1]++
          this.nextRound()
        }
      }
    }
  }

  // Switch to next round.
  private nextRound () : void {
    // WIP
    if (!this.classOptions.readOnlyMode) {
      // Add another scores result element to the rounds score array.
      this.roundScore.push([0, 0])
      this.currentRound = this.roundScore.length - 1

      // Reset the current player to the starting player.
      this.currentPlayerTurn = this.classOptions.playerStartingFirstRound
    }
  }

  // Switch turn to set a score entry back to previous player.
  public previousPlayerTurn () : void {
    if (!this.classOptions.readOnlyMode) {
      if (this.realRoundsPlayed[(!this.currentPlayerTurn ? 1 : 0)] >= 1) {
        this.realRoundsPlayed[(!this.currentPlayerTurn ? 1 : 0)]--
        this.numberOfClosingArrows = null
      }

      if (this.currentPlayerTurn !== this.classOptions.playerStartingFirstRound) {
        // Just revert the turn to the other player from another team.
        this.currentPlayerTurn = !this.currentPlayerTurn
      } else {
        // Go back to previous round.
        this.previousRound()
      }
    }
  }

  // Switch back to previous round.
  private previousRound () : void {
    if (!this.classOptions.readOnlyMode) {
      if (this.roundScore.length >= 2) {
        // Remove the last scores result element from the rounds score array.
        this.roundScore.pop()
      }

      this.currentRound = this.roundScore.length - 1

      // Reset the current player to not be the starting player.
      this.currentPlayerTurn = !this.classOptions.playerStartingFirstRound
    }
  }

  // Reset the rounds scores array to starting condition.
  public clearAllRounds () : void {
    // WIP
    if (!this.classOptions.readOnlyMode) {
      //
      this.roundScore = []
      this.roundScore.push([0, 0])
      this.currentRound = 0
      this.realRoundsPlayed = [0, 0]
      this.currentPlayerTurn = this.classOptions.playerStartingFirstRound
      this.numberOfClosingArrows = null

      this.updateMatchSetRoundsStatus()
    }
  }

  // Undo the latest player score entry in the current round, and switch turn back to previous player.
  public undoLatestRoundPlayerScoreEntry () : void {
    // WIP
    if (!this.classOptions.readOnlyMode && this.roundScore.length >= 1) {
      if (this.roundScore.length > 1 || (this.roundScore.length === 1 && this.currentPlayerTurn !== this.classOptions.playerStartingFirstRound)) {
        this.previousPlayerTurn()
      }
      this.updateMatchSetRoundsStatus()
    }
  }

  // Set the score entry value for the current player in the current round.
  public setCurrentRoundCurrentPlayerScore (scoreVal: number) : void {
    if (!this.classOptions.readOnlyMode) {
      // Check that the specified score value is valid. The value should be positive, and be less than or equal to the maximum score value of the game, and also less than or equal to the remaining points left in the match set.
      if (scoreVal >= 0) {
        if (scoreVal <= (this.classOptions.arrowsPerRound * 20 * 3) && scoreVal !== 179 && scoreVal !== 178 && scoreVal !== 176 && scoreVal !== 175 && scoreVal !== 173 && scoreVal !== 172 && scoreVal !== 169) {
          if (scoreVal <= this.getPlayersPointsRemaining()[(this.currentPlayerTurn ? 1 : 0)] && (this.getPlayersPointsRemaining()[(this.currentPlayerTurn ? 1 : 0)] - scoreVal) !== 1) {
            this.roundScore[this.currentRound][(this.currentPlayerTurn ? 1 : 0)] = scoreVal
          }
        }
      }

      this.updateMatchSetRoundsStatus()
    }
  }

  public setCurrentRoundCurrentPlayerScoreToEdit (value: any) : void {
    const remainingPointsincludingEdit = this.getPlayersPointsRemaining()[value.playerTurn] + this.roundScore[value.turnIndex][value.playerTurn]

    if (!this.classOptions.readOnlyMode) {
      // Check that the specified score value is valid. The value should be positive, and be less than or equal to the maximum score value of the game, and also less than or equal to the remaining points left in the match set.
      if (value.score >= 0) {
        if (value.score <= (this.classOptions.arrowsPerRound * 20 * 3) && value.score !== 179 && value.score !== 178 && value.score !== 176 && value.score !== 175 && value.score !== 173 && value.score !== 172 && value.score !== 169) {
          // if (value.score <= this.getPlayersPointsRemaining()[value.playerTurn] && (this.getPlayersPointsRemaining()[value.playerTurn] - value.score) !== 1) {
          if (value.score <= remainingPointsincludingEdit && (remainingPointsincludingEdit - value.score) !== 1) {
            this.roundScore[value.turnIndex][value.playerTurn] = value.score
          } else {
            this.roundScore[value.turnIndex][value.playerTurn] = 0
          }
        } else {
          this.roundScore[value.turnIndex][value.playerTurn] = 0
        }
      }

      this.updateMatchSetRoundsStatus()
    }
  }

  public setRoundScoreAfterEditRemaining (value: any) : void {
    if (!this.classOptions.readOnlyMode) {
      if (value.score >= 0) {
        if (value.score <= (this.classOptions.arrowsPerRound * 20 * 3) && value.score !== 179 && value.score !== 178 && value.score !== 176 && value.score !== 175 && value.score !== 173 && value.score !== 172 && value.score !== 169) {
          this.roundScore[this.currentRound][value.playerTurn] = value.score
        } else {
          this.roundScore[this.currentRound][value.playerTurn] = 0
        }
      }

      this.updateMatchSetRoundsStatus()
    }
  }

  // Get the score entry value for the current player in the current round.
  public getCurrentRoundCurrentPlayerScore () : number {
    return this.roundScore[this.currentRound][(this.currentPlayerTurn ? 1 : 0)]
  }

  // Modify the value of a score entry of certain round and for a certain player.
  public changePlayerScoreEntryOfRound (roundVal: number, playerTurn: boolean, scoreVal: number) : void {
    if (!this.classOptions.readOnlyMode && roundVal >= 0) {
      // Check that the desired score entry exist.
      if (this.roundScore[roundVal][(playerTurn ? 1 : 0)] !== undefined) {
        // Check that the specified score value is valid. The value should be positive, and be less than or equal to the maximum score value of the game, and also less than or equal to the remaining points left in the match set.
        if (scoreVal >= 0) {
          if (scoreVal <= (this.classOptions.arrowsPerRound * 20 * 3) && scoreVal !== 179 && scoreVal !== 178 && scoreVal !== 176 && scoreVal !== 175 && scoreVal !== 173 && scoreVal !== 172 && scoreVal !== 169) {
            if (scoreVal <= this.getPlayersPointsRemaining()[(this.currentPlayerTurn ? 1 : 0)] && (this.getPlayersPointsRemaining()[(this.currentPlayerTurn ? 1 : 0)] - scoreVal) !== 1) {
              this.roundScore[roundVal][(playerTurn ? 1 : 0)] = scoreVal
            }
          }
        }
      }

      this.updateMatchSetRoundsStatus()
    }
  }

  // Set the number of closing arrows used by the winning player.
  public setNumberOfClosingArrowsUsed (nbOfClosingArrows = 3) : void {
    if (!this.classOptions.readOnlyMode) {
      if (nbOfClosingArrows >= 0 && nbOfClosingArrows <= this.classOptions.arrowsPerRound) {
        this.numberOfClosingArrows = nbOfClosingArrows
      } else {
        this.numberOfClosingArrows = null
      }
    }
  }

  // Get the closing score results of a completed match set.
  public getClosingRoundsResults () : number[] | null {
    let retVal = null

    if (this.roundsAreCompleted) {
      // retVal = [this.roundScore[(this.roundScore.length - 1)][0], this.roundScore[(this.roundScore.length - 1)][1]]
      retVal = [this.roundScore[this.realRoundsPlayed[0] - 1][0], this.roundScore[this.realRoundsPlayed[1] - 1][1]]
    }

    return retVal
  }

  // Get the number of rounds played so far.
  public getNumberOfRoundsPlayed () : number {
    return this.currentRound
  }

  // Set the current rounds played.
  public setNumberOfRoundsPlayed (roundNo: number) : void {
    this.currentRound = roundNo
  }

  // Get the score values (for every player) of all the rounds played so far.
  public getScoresOfAllRoundsPlayed () : number[][] {
    return this.roundScore
  }

  // Set the score values (for every player) of all the rounds played so far.
  public setScoresOfAllRoundsPlayed (roundsScoresArr: number[][]) : void {
    if (roundsScoresArr !== null && roundsScoresArr.length >= 1) {
      this.roundScore = roundsScoresArr
    } else {
      this.roundScore = []
      this.roundScore.push([0, 0])
    }
  }

  // Get the average score (for every player) of all the rounds played so far.
  public getAverageScoreOfRoundsPlayed () : number[] {
    const retVal = [0, 0]

    for (let arrIndex = 0; arrIndex < this.roundScore.length; arrIndex++) {
      retVal[0] += this.roundScore[arrIndex][0]
      retVal[1] += this.roundScore[arrIndex][1]
    }

    if (this.winningPlayer === null) {
      retVal[0] = Math.round(retVal[0] / (this.realRoundsPlayed[0] > 0 ? this.realRoundsPlayed[0] : 1))
      retVal[1] = Math.round(retVal[1] / (this.realRoundsPlayed[1] > 0 ? this.realRoundsPlayed[1] : 1))
    } else {
      if (this.winningPlayer) {
        retVal[0] = Math.round(retVal[0] / this.realRoundsPlayed[0])
        retVal[1] = Math.round(retVal[1] / this.realRoundsPlayed[1])
      } else {
        retVal[0] = Math.round(retVal[0] / this.realRoundsPlayed[0])
        retVal[1] = Math.round(retVal[1] / this.realRoundsPlayed[1])
      }
    }

    return retVal
  }

  // Get the remaining number of points (for every player) after the rounds played so far.
  public getPlayersPointsRemainingExceptCurrentPlayer (index: number) : number[] {
    const retVal = [this.classOptions.startingPoints, this.classOptions.startingPoints]
    if (this.currentRound === (this.roundScore.length - 1)) {
      this.roundScore[(this.roundScore.length - 1)][index] = 0
    }
    for (let arrIndex = 0; arrIndex < this.roundScore.length; arrIndex++) {
      retVal[0] -= this.roundScore[arrIndex][0]
      retVal[1] -= this.roundScore[arrIndex][1]
    }

    // Clamp return values to zero if below zero.
    if (retVal[0] < 0) {
      retVal[0] = 0
    }
    if (retVal[1] < 0) {
      retVal[1] = 0
    }

    return retVal
  }

  // Get the remaining number of points (for every player) after the rounds played so far.
  public getPlayersPointsRemaining () : number[] {
    const retVal = [this.classOptions.startingPoints, this.classOptions.startingPoints]

    for (let arrIndex = 0; arrIndex < this.roundScore.length; arrIndex++) {
      retVal[0] -= this.roundScore[arrIndex][0]
      retVal[1] -= this.roundScore[arrIndex][1]
    }

    // Clamp return values to zero if below zero.
    if (retVal[0] < 0) {
      retVal[0] = 0
    }
    if (retVal[1] < 0) {
      retVal[1] = 0
    }

    return retVal
  }

  // Get the number of rounds played by each player.
  public getNumberOfRoundsPlayedByPlayers () : number[] {
    return this.realRoundsPlayed
  }

  // Set the number of rounds played by each player.
  public setNumberOfRoundsPlayedByPlayers (realRoundsPlayedArr: number[]) : void {
    if (realRoundsPlayedArr !== null && realRoundsPlayedArr.length === 2) {
      this.realRoundsPlayed = realRoundsPlayedArr
    } else {
      this.realRoundsPlayed = [0, 0]
    }
  }

  // Return the number of occurrences of the score value 180 in the array of the round scores for both players.
  public getTotalMatch180ScoreAmount () {
    console.log('[getTotalMatch180ScoreAmount()] this.roundScore[0] = ' + JSON.stringify(this.roundScore[0]))
    this.totalMatch180ScoreAmount = [this.roundScore.filter(elementValArr => elementValArr[0] === 180).length, this.roundScore.filter(elementValArr => elementValArr[1] === 180).length]

    return this.totalMatch180ScoreAmount
  }

  // Get some extra information about the played match set.
  public getExtraInfoAboutTheRounds () : { isRoundCompleted: boolean; winningPlayer: boolean | null; numberOfClosingArrows: number | null; remainingPointsAtTheFinalRound: number | null; currentRound: number; currentPlayerTurn: boolean; } {
    return { isRoundCompleted: this.roundsAreCompleted, winningPlayer: this.winningPlayer, numberOfClosingArrows: this.numberOfClosingArrows, remainingPointsAtTheFinalRound: this.remainingPointsAtTheFinalRound, currentRound: this.currentRound, currentPlayerTurn: this.currentPlayerTurn }
  }

  // Set some extra information about the played match set.
  public setExtraInfoAboutTheRounds (extraInfoObj: any) : void {
    if (extraInfoObj !== null) {
      if (extraInfoObj.isRoundCompleted !== undefined && extraInfoObj.isRoundCompleted !== null) {
        this.roundsAreCompleted = extraInfoObj.isRoundCompleted
      } else {
        this.roundsAreCompleted = false
      }
      if (extraInfoObj.winningPlayer !== undefined && extraInfoObj.winningPlayer !== null) {
        this.winningPlayer = extraInfoObj.winningPlayer
      } else {
        this.winningPlayer = null
      }
      if (extraInfoObj.numberOfClosingArrows !== undefined && extraInfoObj.numberOfClosingArrows !== null) {
        this.numberOfClosingArrows = extraInfoObj.numberOfClosingArrows
      } else {
        this.numberOfClosingArrows = null
      }
      if (extraInfoObj.remainingPointsAtTheFinalRound !== undefined && extraInfoObj.remainingPointsAtTheFinalRound !== null) {
        this.remainingPointsAtTheFinalRound = extraInfoObj.remainingPointsAtTheFinalRound
      } else {
        this.remainingPointsAtTheFinalRound = null
      }
      if (extraInfoObj.currentRound !== undefined && extraInfoObj.currentRound !== null) {
        this.currentRound = extraInfoObj.currentRound
      } else {
        this.currentRound = 0
      }
      if (extraInfoObj.currentPlayerTurn !== undefined && extraInfoObj.currentPlayerTurn !== null) {
        this.currentPlayerTurn = extraInfoObj.currentPlayerTurn
      } else {
        this.currentPlayerTurn = true
      }
    }
  }

  // Get the class options values.
  public getClassOptions () : typeof Tournament501MatchSetRounds.defaultOptions {
    return this.classOptions
  }

  // Check for possible number of dart arrows used in the closing round by the winning player and return the possible values in an array.
  public checkForPossibleClosingNumberOfDartArrowsThrown (scoreVal = 0) : number[] {
    let retVal = [1, 2, 3]

    // If the passed score value is out of valid range, then use the score value of the winning play (if there is one).
    if (scoreVal < 1 && this.winningPlayer !== null) {
      scoreVal = this.roundScore[this.realRoundsPlayed[(this.winningPlayer ? 1 : 0)] - 1][(this.winningPlayer ? 1 : 0)]
    } else {
      retVal = []
    }

    console.log('[checkForPossibleClosingNumberOfDartArrowsThrown()] scoreVal = ' + scoreVal.toString())

    if (scoreVal >= 1 && scoreVal <= (this.classOptions.arrowsPerRound * 20 * 3)) {
      retVal = []

      // Evaluate for 1 dart arrow throw closing score possibility.
      switch (scoreVal) {
        case 2:
        case 4:
        case 6:
        case 8:
        case 10:
        case 12:
        case 14:
        case 16:
        case 18:
        case 20:
        case 22:
        case 24:
        case 26:
        case 28:
        case 30:
        case 32:
        case 34:
        case 36:
        case 38:
        case 40:
        case 50:
          retVal.push(1)
          break
      }

      // Evaluate for 2 dart arrows throws closing score possibility.
      if ((scoreVal >= 2 && scoreVal <= 90) || scoreVal === 91 || scoreVal === 92 || scoreVal === 93 || scoreVal === 94 || scoreVal === 95 || scoreVal === 96 || scoreVal === 97 || scoreVal === 98 || scoreVal === 100 || scoreVal === 101 || scoreVal === 104 || scoreVal === 107 || scoreVal === 110) {
        retVal.push(2)
      }

      // Evaluate for 3 dart arrows throws closing score possibility.
      if (scoreVal >= 2 && scoreVal <= 170 && scoreVal !== 169 && scoreVal !== 168 && scoreVal !== 166 && scoreVal !== 165 && scoreVal !== 163 && scoreVal !== 162 && scoreVal !== 159) {
        retVal.push(3)
      }
    }

    return retVal
  }

  public checkForAllowableClosingScore (scoreVal = 0) : boolean {
    if (scoreVal < 0 || scoreVal === 1 || scoreVal > 170 || scoreVal === 169 || scoreVal === 168 || scoreVal === 166 || scoreVal === 165 || scoreVal === 163 || scoreVal === 162 || scoreVal === 159) {
      return false
    } else {
      return true
    }
  }

  public getClosingRoundsResultsAtEdit () : number[] | null {
    const retVal = [this.roundScore[this.realRoundsPlayed[0] - 1][0], this.roundScore[this.realRoundsPlayed[1] - 1][1]]

    return retVal
  }

  public getClosingRoundsPosition () : number[] {
    const closingRoundIndex0 = this.realRoundsPlayed[0] - 1
    const closingRoundIndex1 = this.realRoundsPlayed[1] - 1

    return [closingRoundIndex0, closingRoundIndex1]
  }

  public checkForAllowableClosingScoreBeforEdit (scoreVal = 0) : boolean {
    if (scoreVal < 0 || scoreVal === 1 || scoreVal > 170 || scoreVal === 169 || scoreVal === 168 || scoreVal === 166 || scoreVal === 165 || scoreVal === 163 || scoreVal === 162 || scoreVal === 159) {
      return false
    } else {
      return true
    }
  }

  public checkForClosingScoreConditionOfCurrentPlayer (scoreVal = 0) : boolean {
    return (this.getPlayersPointsRemaining()[(this.currentPlayerTurn ? 1 : 0)] - scoreVal) === 0
  }

  public checkForClosingScoreConditionOfCurrentPlayerAtEdit (value: any) : boolean {
    const remainingClosingPointsincludingEdit = this.getPlayersPointsRemaining()[value.playerTurn] + this.roundScore[value.turnIndex][value.playerTurn]

    return (remainingClosingPointsincludingEdit - value.score) === 0
  }

  // Update some internal match set state information.
  public updateMatchSetRoundsStatus () : void {
    // const tempOriginalRoundsAreCompleted = this.roundsAreCompleted

    if (!this.classOptions.readOnlyMode) {
      const pointsRemaining = this.getPlayersPointsRemaining()

      if (pointsRemaining[0] !== 0 && pointsRemaining[1] !== 0) {
        this.roundsAreCompleted = false
        this.winningPlayer = null
        this.remainingPointsAtTheFinalRound = null
      }

      if (this.classOptions.playerStartingFirstRound) {
        if (pointsRemaining[0] === 0 && pointsRemaining[1] !== 0) {
          this.roundsAreCompleted = true
          this.winningPlayer = false
          this.remainingPointsAtTheFinalRound = pointsRemaining[1]
        }
        if (pointsRemaining[1] === 0) {
          this.roundsAreCompleted = true
          this.winningPlayer = true
          this.remainingPointsAtTheFinalRound = pointsRemaining[0]
        }
      } else {
        if (pointsRemaining[1] === 0 && pointsRemaining[0] !== 0) {
          this.roundsAreCompleted = true
          this.winningPlayer = true
          this.remainingPointsAtTheFinalRound = pointsRemaining[0]
        }
        if (pointsRemaining[0] === 0) {
          this.roundsAreCompleted = true
          this.winningPlayer = false
          this.remainingPointsAtTheFinalRound = pointsRemaining[1]
        }
      }
    }

    // if (this.roundsAreCompleted === true && tempOriginalRoundsAreCompleted === false) {
    //   if (this.currentPlayerTurn !== this.classOptions.playerStartingFirstRound) {
    //     // Advance to a new round.
    //     this.nextRound()
    //   }
    // }
  }
}
